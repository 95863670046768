:root {
	--bg-color: #ffff;
	--body-bg-color: #ffff;
	--color: #313131;
	--border-color: #dee2e6;
	--title-color: #009edb;
	--subtitle-color: #323070;
	--dark-gray: #919191;

	/* Snackbar colors */
	--success: #248226;
	--error: #da100b;
	--info: #0e55ad;
	--warning: #bb6f0d;
}

body.dark {
	--bg-color: #262626;
	--color: #ffff;
	--border-color: #404040;
	--subtitle-color: #e6e6e6;
	--body-bg-color: #252525;
}
/* ---------- Estilos custom snackbar ------------*/
.snackbar {
	font-weight: 600;
	font-size: 0.9rem !important;
	padding-block: 0 !important;
	padding-inline: 0.7rem !important;
	background-color: red;
	border-radius: 0.5rem !important;
}

.snackbar.success {
	color: var(--success);
	background: #dff1d9;
}

.snackbar.info {
	color: var(--info);
	background: #dfe7ef;
}

.snackbar.error {
	color: var(--error);
	background: #f9e1dc;
}

.snackbar.warning {
	color: var(--warning);
	background: #f9ead8;
}

/* ----------------------------------------- */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	max-height: 100vh;
	overflow-y: hidden;
	background: var(--body-bg-color) !important;
	color: var(--color) !important;
}

.bg-theme {
	background: var(--bg-color) !important;
	color: var(--color) !important;
}
/* ---- configuracion de estilos select de react-select ----- */

.select.__placeholder {
	z-index: 0;
	color: var(--dark-gray) !important;
}

.select.__single-value {
	z-index: 0;
	color: var(--color) !important;
}

.select.__multi-value__label {
	background: #323070 !important;
	color: #fff !important;
	font-weight: 600;
	padding-inline: 0.9rem;
	border-radius: 0.3rem 0 0 0.3rem;
}

.select.__multi-value__remove {
	background: #323070 !important;
	color: #fff !important;
	border-left: 1px solid transparent;
	border-radius: 0 0.3rem 0.3rem 0;
}

/* ---------------------------------------------------------- */

body .border {
	border: 1px solid var(--border-color) !important;
}

.border-color {
	border-color: var(--border-color) !important;
}

body .form-check-input[type='radio'] {
	background-color: var(--bg-color) !important;
	border: 5px solid var(--border-color) !important;
}

body .form-check-input[type='checkbox'] {
	background-color: var(--border-color) !important;
}

body .form-check-input:checked[type='checkbox'] {
	background-color: var(--title-color) !important;
}

body .form-check-input:checked[type='radio'] {
	background-image: linear-gradient(
		to right top,
		var(--bg-color),
		var(--bg-color)
	);
	border: 5px solid var(--title-color) !important;
}

/* Tipografia de clase especifica. */
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.form-check-input:checked {
	background-color: #009edb !important;
	border-color: #009edb !important;
}

button.border-primary {
	border-color: #009edb !important;
}

.primary,
.btn-primary {
	background-color: #009edb !important;
	border-color: #009edb !important;
}

* {
	padding: 0;
	margin: 0;
	border: transparent;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

button {
	background: transparent;
}
