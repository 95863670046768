.loader_container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: var(--bg-color);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	transition: all 0.3s ease;
}

.loader {
	width: 68px;
	height: 68px;
	border-radius: 50%;
	display: inline-block;
	border: 3px solid;
	border-color: var(--title-color) var(--subtitle-color) transparent transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	transition: all 0.3s ease;
}

.loader::after,
.loader::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 3px solid;
	border-color: transparent transparent var(--title-color) var(--title-color);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	box-sizing: border-box;
	animation: rotationBack 0.5s linear infinite;
	transform-origin: center center;
	transition: all 0.3s ease;
}

.loader::before {
	width: 42px;
	height: 42px;
	border-color: var(--subtitle-color) var(--subtitle-color) transparent
		transparent;
	animation: rotation 1.5s linear infinite;
	transition: all 0.3s ease;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
