@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

*::-webkit-scrollbar {
	background-color: transparent;
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 1rem;
}

*::-webkit-scrollbar-thumb {
	background-color: #009edb;
	border-radius: 1rem;
}

@media screen and (max-width: 800px) {
	*::-webkit-scrollbar {
		height: 0.5rem;
		border-radius: 1rem;
	}
}

.contenedor-principal {
	background: url('./assets/img/formbg.png') no-repeat;
	background-size: cover;
	position: relative;
}
.contenedor-principal::after {
	content: '';
	width: 600px;
	height: 539px;
	position: fixed;
	top: 25vh;
	right: 5rem;
	background: url('./assets/img/desconnect.png') no-repeat;
	background-size: cover;
}

.children {
	z-index: 2;
}

/* Alineación de teto */
.App {
	text-align: center;
}

/* Altura de logo */
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

/* Animación de SPIN / LOADER ↓↓ */
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

/* Animación de SPIN / LOADER. */
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Alineación en columna */
.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
/* PARA EL COLOR DEL LINK DE LOS BOTONES DE LAS OPCIONES*/
.App-link {
	color: #61dafb;
}

/* Color de fondo de la barra */
.barra {
	background-color: #009edb;
}

/* Clase para los paneles izquierdo y derecho de la aplicacion */
.tamanoColorPaneles {
	position: relative;
	top: 85px;
	height: 594px;
	border-radius: 10px;
	margin-left: 22px;
	margin-right: 22px;

	background-color: #142d34;
}

/* Tamaño de fila de Login */
.tamaño-Fila-Login {
	height: 100vh;
	width: 100vw;
}

/*Color de fondo y animacion del panel superior del componente pacientes */
.animacionFilaSuperior-Pacientes {
	background-color: rgb(216, 227, 231);

	height: 270px;
	border-radius: 10px;
	animation: myAnim 1s linear 0s 1 alternate both;
}

/*Animacion del panel ↓↓ */
@keyframes myAnim {
	0% {
		opacity: 0;
		transform: rotateX(-100deg);
		transform-origin: top;
	}

	100% {
		opacity: 1;
		transform: rotateX(0deg);
		transform-origin: top;
	}
}

/* Color de fondo y animacion de la fila inferior del panel pacientes */
.animacionFilaInferior-Pacientes {
	background-color: rgb(216, 227, 231);
	animation: myAnim 1s linear 0s 1 alternate both;
	height: 240px;
	border-radius: 10px;
}

/* Clase para el div de las citas medicas del panel izquierdo */
.tamanoListaCitasMedicas {
	overflow-y: scroll;
	scroll-behavior: smooth;
	display: block;
	width: 380px;
	height: 350px;

	color: rgb(82, 196, 211);
	background-color: rgb(216, 227, 231);
}

/* Clase para el div de los compromisos del panel izquierdo */
.tamanoListaCompromisos {
	background-color: rgb(216, 227, 231);

	display: block;
	height: 145px;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

/* Clase para los titulos de los divs internos */
.Titulos {
	font-size: 27px;
	font-weight: bold;
	color: #52c4d3;
	text-align: center;
}

/* ScrollBar del div interno de las citas medicas */
.tamanoListaCitasMedicas::-webkit-scrollbar {
	width: 7px;

	background-color: rgb(20, 45, 52);
}

/* THUM DEL SCROL DEL DIV DE LAS CITAS MEDICAS */
.tamanoListaCitasMedicas::-webkit-scrollbar-thumb {
	background-color: rgb(82, 196, 211);
}

/* ScrollBar DEL DIV INTERNO DE LOS COMPROMISOS */
.tamanoListaCompromisos::-webkit-scrollbar {
	width: 7px;

	background-color: rgb(20, 45, 52);
}

/* THUM DEL SCROL DEL DIV DE LOS COMPROMISOS */
.tamanoListaCompromisos::-webkit-scrollbar-thumb {
	background-color: rgb(82, 196, 211);
}

/* Cambio de tamaño -- none */
.textarea {
	resize: none;
}

/* Validar clase */
.test {
	background-color: #009edb;
}

/* Color de fondo */
.nav-link.active {
	background-color: #e0f6ff;
}

/* Verificar Nombre de clase */
.pruebaPanel {
	background: rgba(0, 158, 219, 0.8);

	backdrop-filter: blur(3.5px);
	-webkit-backdrop-filter: blur(3.5px);
	border-radius: 10px;
}

/* definición de altura */
.lotesTamano {
	height: 300px;
}

/* Winner Formulario   */

.tn {
	font-family: 'Montserrat', sans-serif;
}
/* Validar Nombre de clase */
.tnt {
	font-weight: 900 !important;
	color: #009fdb;
}
/* Validar Nombre de clase */
.tns {
	font-weight: 700 !important;
	color: var(--subtitle-color);
}
/* Validar Nombre de clase */
.formul {
	padding-top: 2em;
}
/* Validar Nombre de clase */
.topito {
	padding-top: 2em;
}
.h2 {
	padding-bottom: 0.5em;
}
/* Barra de fondo */
.btnosnet {
	background: #009fdb !important;
	border: 0 !important;
}
/* Color de letra */
.ttab.active {
	color: #009fdb !important;
}
/* Color de letra */
.ttab {
	color: #323070 !important;
}
/* Imagen/logo de fondo */
.bgosnet {
	background-image: url('./assets/img/formbg.png');
	background-size: cover;
}

/* altura de fila */
.FondoFila {
	height: 905px;
}

/* Animación de panel ↓↓*/
.animacionPanel {
	animation: myAnim 1s ease 0s 1 normal forwards;
}

/* Animación de panel -- keyframe ↑↑ */
@keyframes myAnim {
	0% {
		opacity: 0;
		transform: rotateX(-100deg);
		transform-origin: top;
	}

	100% {
		opacity: 1;
		transform: rotateX(0deg);
		transform-origin: top;
	}
}

/* Animación de login ↓↓ */
.animacionLogin {
	animation: myAnimPanel 1s ease 0s 1 normal forwards;
}

/* Animación de Login -- keyframe ↑↑↓ */
@keyframes myAnimPanel {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

/* formato para caja de contenido */
.cajaListaCircuitos {
	border-radius: 0.5rem;
	padding: 0.5rem;
	height: 280px;
	overflow-y: auto;
	scroll-behavior: smooth;
	border: #ced4da 1px solid;
}

/* ancho y color de fondo  */
.cajaListaCircuitos::-webkit-scrollbar {
	width: 7px;

	background-color: rgb(255, 255, 255);
}

/* Color de fondo */
.cajaListaCircuitos::-webkit-scrollbar-thumb {
	background-color: #009fdb;
}

/* Tamaño de caja principal ↓↓ */
.tamanoCajaPrincipal {
	height: 1000px;
}

/* ANIMACION PARA EL SIMBOLO DE CARGA CUANDO SE HAGAN LAS PETICIONES */
.animacionCarga {
	animation: GIRAR 1s ease 0s infinite normal forwards;
}
@keyframes GIRAR {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
/* Validar Nombre de clase  */
/* estilo borde izquierdo */
.fondoL {
	border-left: 10px solid #61dafb;
}

/* posicionamiento de caja para peticion (correo) */
.email-usersBox {
	position: absolute;
	top: 0;
	left: 80%;
	/* background-color: green; */
}
/* Boton properties - tamaño y fondo*/
.btn-properties {
	padding: 4px 10px 4px 10px;
	border-radius: 20px !important;
	margin-right: 1rem;
	outline: none;
	background-color: #ffffff;
	border: none;
	transition: all 0.1s ease;
}

/* Definición de borde y fondo de boton sub-menú */
.sub-menu-active {
	padding: 4px 10px 4px 10px;
	border-radius: 20px !important;
	border: 2px solid rgb(144, 144, 144) !important;
	background-color: #fff;
}
/* Cambio de color de fondo - estado hover */
.btn-properties:hover {
	background-color: rgba(145, 145, 145, 0.226);
}

.nowrapData {
	white-space: nowrap;
}

/* Valida si es clase, id ↓↓ */
td {
	font-size: large;
	font-weight: 500;
}

/* Margenes de caja con cursor */
/* .customSelectJp {
  margin-right: 0.7rem;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border: 1px solid #ced4da;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
} */

/* Caja flotante */
.connection {
	display: flex;
	font-size: 1.3rem;
	font-weight: bold;
	align-items: center;
	color: #282c34;
	border-radius: 0 0 10px 0;
	position: sticky;
	top: 0;
	width: 99%;
	height: 7vh;
	background-color: #d1e6f5cb;
	z-index: 1;
}
/* Espacios de margen. */
.connection p {
	margin: 0;
	margin-left: 1rem;
}

.Labelb__Averias {
	margin-right: 1rem;
	cursor: pointer;
	font-size: 18px;
}
.Labelb__Desvios {
	cursor: pointer;
	font-size: 18px;
}
input[id='Averias']:checked ~ .Labelb__Averias {
	padding: 0.3rem;
	border-radius: 10px;
	background-color: #0281b3;
	transition: all ease 0.5s;
	color: #ffffff;
}

input[id='Desvios']:checked ~ .Labelb__Desvios {
	padding: 0.3rem;
	border-radius: 10px;
	background-color: #0281b3;
	transition: all ease 0.5s;
	color: #ffffff;
}

.btn-prev {
	border: none;
	background-color: transparent;
}
.btn-next {
	border: none;
	background-color: transparent;
}
.current-page {
	font-size: 24px;
	font-weight: 500;
	margin: 0 0.5rem;
}
